import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { SectionWrapper } from '../../components/global/flexbox';
import { Column, Row } from '../../components/global/utils';
import { respondTo } from '../../utils/breakpoints';

export const ArticleTimeIcon = styled(FontAwesomeIcon)`
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.uiBlue};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.uiDarkBlue};
  cursor: pointer;
  display: flex;
  height: 1.2rem;
  justify-content: center;
  margin-right: 1.5rem;
  min-width: 1.2rem;
  padding: 0.3rem;
  ${respondTo.m`
    margin-right: 0;
    margin-left: 1.5rem;
  `}
  &.circle {
    color: ${(props) => props.theme.colors.uiDarkBlue};
    border: 1px solid ${(props) => props.theme.colors.uiBlue};
    border-radius: 50%;
    opacity: 1;
    margin-left: 0;
    margin-right: 1rem;
  }
  &.disabled {
    border: 1px solid grey;
    color: gray;
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const ArticlesWrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 776px;
  &.border-bottom {
    border-bottom: 1px solid ${(props) => props.theme.colors.uiBlue};
  }
  &.breadcrumbs {
    align-items: flex-start;
  }
  ${respondTo.xs`
    width: auto;
  `}
`;

export const ArticleHeaders = styled.div`
  width: 100%;
  ${respondTo.m`
    width: 776px;
  `}
`;

export const ArticleTitle = styled.h1`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  line-height: 59px;
  font-size: 48px;
  overflow-wrap: anywhere;
  letter-spacing: -0.02em;
  margin: 1rem 0;
  ${respondTo.m`
    overflow-wrap: anywhere;

  `}
`;

export const ArticleType = styled.div`
  font-family: 'Montserrat';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
`;

export const AuthorImg = styled.img`
  border-radius: 50%;
  height: 2rem;
  margin-right: 0.75rem;
  width: 2rem;
`;

export const ArticleHeader = styled(Column)`
  padding-top: 2rem;
`;

export const AuthorBox = styled(Row)`
  align-items: center;
  border-right: none;
  justify-content: flex-start;
  line-height: 150%;
  margin: 0.5rem 0;
  font-family: 'Raleway' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  > * span {
    color: ${(props) => props.theme.colors.uiBlue};
  }
  &.end {
    > * span {
      padding-bottom: 0.5rem;
      ${respondTo.m`
        text-align: right;
      `}
    }
  }
  &:first-of-type {
    padding-left: 0;
    width: 186px;
  }
  &.no-border {
    border-right: none;
  }
  ${respondTo.m`
    border-right: 1px solid ${(props) => props.theme.colors.uiBlue};
    margin: 1rem 0;
    padding: 0 1rem;
    width: 153px;
  `}
`;

export const ArticleDetails = styled(Row)`
  padding: 2rem 0;
`;

export const ArticleContent = styled(Column)`
  padding: 3rem 0;
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  a,
  b,
  i,
  img {
    ${respondTo.m`
      max-width: 776px;
    `}
  }
  > img {
    &:first-of-type {
      width: auto;
    }
  }
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  img {
    margin-bottom: 2rem;
  }
  > ul,
  ol {
    margin-top: -1rem;
  }
  a {
    color: var(--inhub-blue);
    font-weight: 500;
  }
  p {
    line-height: 1.4;
  }
`;

export const ReadingTime = styled.div`
  color: ${(props) => props.theme.colors.uiBlue};
`;

export const ArticleThumbnail = styled.img`
  padding-top: 2rem;
  max-width: 100%;
  ${respondTo.m`
    max-width: 1180px
  `}
`;
